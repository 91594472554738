import React, { Component } from 'react';
import { arrayOf, func, node, number, shape, string, object } from 'prop-types';
import classNames from 'classnames';

import { injectIntl, intlShape } from '../../../util/reactIntl';
import { parse as parseUrl } from '../../../util/urlHelpers';

import { OutsideClickHandler } from '../../../components';

import PopupOpenerButton from '../PopupOpenerButton/PopupOpenerButton';
import WhenFilterForm from '../WhenFilterForm/WhenFilterForm';
import { compact } from 'lodash';
import css from './WhenFilterPopup.module.css';

const KEY_CODE_ESCAPE = 27;

const getWhenQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames)
    ? queryParamNames[0]
    : typeof queryParamNames === 'object'
    ? queryParamNames
    : 'when';
};

const parse = when => {
  const { month, year } = !!when ? parseUrl(when) : {};
  const months =
    typeof month === 'number' ? [month] : compact(month?.split(','))?.map(m => parseInt(m)) || [];
  const years =
    typeof year === 'number' ? [year] : compact(year?.split(','))?.map(m => parseInt(m)) || [];
  return !!months?.length > 0 && years?.length > 0
    ? { month: months, year: years }
    : !!months?.length > 0
    ? { month: months }
    : !!years?.length > 0
    ? { year: years }
    : null;
};

const format = (when, queryParamName) => {
  const { month, year } = when || {};
  const value =
    !!month && !!year
      ? `month=${month}&year=${year}`
      : !!month
      ? `month=${month}`
      : !!year
      ? `year=${year}`
      : null;
  return { [queryParamName]: value };
};

class WhenFilterPopup extends Component {
  constructor(props) {
    super(props);

    this.state = { isOpen: false, selectedMonths: [], selectedYears: [] };
    this.filter = null;
    this.filterContent = null;

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.toggleOpen = this.toggleOpen.bind(this);
    this.positionStyleForContent = this.positionStyleForContent.bind(this);
  }

  handleSubmit(values) {
    const { onSubmit, queryParamNames } = this.props;
    this.setState({ isOpen: false });
    const whenQueryParamName = getWhenQueryParamName(queryParamNames);
    onSubmit(format(values, whenQueryParamName));
  }

  handleClear() {
    const { onSubmit, queryParamNames } = this.props;
    this.setState({ isOpen: false });
    const whenQueryParamName = getWhenQueryParamName(queryParamNames);
    onSubmit(format(null, whenQueryParamName));
  }

  handleCancel() {
    const { onSubmit, initialValues } = this.props;
    this.setState({ isOpen: false });
    onSubmit(initialValues);
  }

  handleBlur() {
    this.setState({ isOpen: false });
  }

  handleKeyDown(e) {
    // Gather all escape presses to close menu
    if (e.keyCode === KEY_CODE_ESCAPE) {
      this.toggleOpen(false);
    }
  }

  toggleOpen(enforcedState) {
    if (enforcedState) {
      this.setState({ isOpen: enforcedState });
    } else {
      this.setState(prevState => ({ isOpen: !prevState.isOpen }));
    }
  }

  positionStyleForContent() {
    if (this.filter && this.filterContent) {
      // Render the filter content to the right from the menu
      // unless there's no space in which case it is rendered
      // to the left
      const distanceToRight = window.innerWidth - this.filter.getBoundingClientRect().right;
      const labelWidth = this.filter.offsetWidth;
      const contentWidth = this.filterContent.offsetWidth;
      const contentWidthBiggerThanLabel = contentWidth - labelWidth;
      const renderToRight = distanceToRight > contentWidthBiggerThanLabel;
      const contentPlacementOffset = this.props.contentPlacementOffset;

      const offset = renderToRight
        ? { left: contentPlacementOffset }
        : { right: contentPlacementOffset };
      // set a min-width if the content is narrower than the label
      const minWidth = contentWidth < labelWidth ? { minWidth: labelWidth } : null;

      return { ...offset, ...minWidth };
    }
    return {};
  }

  render() {
    const {
      rootClassName,
      className,
      id,
      label,
      queryParamNames,
      initialValues,
      intl,
    } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    const whenQueryParam = getWhenQueryParamName(queryParamNames);
    const initialWhen =
      initialValues && initialValues[whenQueryParam] ? parse(initialValues[whenQueryParam]) : {};
    const { month, year } = initialWhen || {};
    const hasValue = value => value != null;
    const hasInitialValues = (initialValues && hasValue(month)) || hasValue(year);
    const monthStr = month?.join(',');
    const yearStr = year?.join(',');
    const currentLabel =
      hasInitialValues && hasValue(month) && hasValue(year)
        ? intl.formatMessage(
            { id: 'WhenFilter.labelSelectedButton' },
            {
              month: monthStr,
              year: yearStr,
            }
          )
        : hasInitialValues && hasValue(month)
        ? intl.formatMessage(
            { id: 'WhenFilter.labelMonthSelectedButton' },
            {
              month: monthStr,
            }
          )
        : hasInitialValues && hasValue(year)
        ? intl.formatMessage(
            { id: 'WhenFilter.labelYearSelectedButton' },
            {
              year: yearStr,
            }
          )
        : label
        ? label
        : intl.formatMessage({ id: 'WhenFilter.label' });

    const labelStyles = hasInitialValues ? css.labelSelected : css.label;
    const contentStyle = this.positionStyleForContent();

    return (
      <OutsideClickHandler onOutsideClick={this.handleBlur}>
        <div
          className={classes}
          onKeyDown={this.handleKeyDown}
          ref={node => {
            this.filter = node;
          }}
        >
          <PopupOpenerButton isSelected={hasInitialValues} toggleOpen={this.toggleOpen}>
            {currentLabel}
          </PopupOpenerButton>

          <WhenFilterForm
            id={id}
            initialValues={hasInitialValues ? initialWhen : { month: month, year: year }}
            onClear={this.handleClear}
            onCancel={this.handleCancel}
            onSubmit={this.handleSubmit}
            intl={intl}
            contentRef={node => {
              this.filterContent = node;
            }}
            style={contentStyle}
            // month={month}
            // year={year}
            showAsPopup
            isOpen={this.state.isOpen}
          />
        </div>
      </OutsideClickHandler>
    );
  }
}

WhenFilterPopup.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  contentPlacementOffset: 0,
  liveEdit: false,
};

WhenFilterPopup.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  label: node,
  queryParamNames: arrayOf(string).isRequired,
  onSubmit: func.isRequired,
  initialValues: shape({
    when: object,
  }),
  contentPlacementOffset: number,
  month: number.isRequired,
  year: number.isRequired,

  // form injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(WhenFilterPopup);
