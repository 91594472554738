import React from 'react';
import { bool } from 'prop-types';

import WhenFilterPlain from './WhenFilterPlain';
import WhenFilterPopup from './WhenFilterPopup';

const WhenFilter = props => {
  const { showAsPopup, ...rest } = props;
  return showAsPopup ? <WhenFilterPopup {...rest} /> : <WhenFilterPlain {...rest} />;
};

WhenFilter.defaultProps = {
  showAsPopup: false,
};

WhenFilter.propTypes = {
  showAsPopup: bool,
};

export default WhenFilter;
